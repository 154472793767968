.btnFacebook {
  display: flex !important;
  background: #4267b2;
  border: 1px solid #4267b2;
  border-radius: 2px;
  width: 80%;
  min-width: 20em;
  margin: auto !important;
  cursor: pointer;
  color: white;
  font-size: 0.875em !important;
  height: 60px;
  padding: 0 !important;
  align-items: center;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  font-size: 1em !important;
}

.btnFacebook i {
  margin-right: 35px;
  margin-left: 25px;
  padding-top: 3px;
  padding-bottom: 2px;
}

.btnFacebook:hover {
  background: #3b5998;
  opacity: 0.8;
}


.signInButton {
  display:flex;
  margin:auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
  width: 80%;
  min-width: 20em;
  text-align: center;
  padding: 10px;
  height: 60px;
  font-size: 1em !important;
}
.signInButton div{
  margin-left: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.signInButton span{
  margin-left: 25px;
}
