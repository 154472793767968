.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  margin-bottom: 5%;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  border: 2px solid #d1d1d5;
  cursor: pointer;
  background-color: #d1d1d5;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 1px;
  top: 1px;
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  transform: translateX(30px);
  background-color: #5590f5;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: white;
}
