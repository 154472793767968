/* Tooltip container */
.tooltip {

}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  /* background-color: #f7f7f7; */
  background-color: #d1d1d5;
  color: #292b2c;
  padding: 0.75em 0.75em;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  /* width: 30%; */
  margin-left: -30%;
  margin-right: 5%;
  margin-top: 2.5em;
}


/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}
