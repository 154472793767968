.radioButtonContainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 30px;
}

/* Hide the browser's default radio button */
.radioButtonContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioButtonCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
  transform: translateY(-50%);
}

/* On mouse-over, add a grey background color */
.radioButtonContainer:hover input ~ .radioButtonCheckmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioButtonContainer input:checked ~ .radioButtonCheckmark {
  background-color: #f46029;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioButtonCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioButtonContainer input:checked ~ .radioButtonCheckmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioButtonContainer .radioButtonCheckmark:after {
  top: 5px;
  left: 5px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: white;
}
